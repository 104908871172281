import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

const StyledProjectThumbnailsGrid = styled.div`
  display: inline-grid;
  justify-content: center;
  grid-gap: ${rem(`16px`)};
  justify-items: center;
  align-items: center;
  grid-template-columns: repeat(auto-fill, 200px);
  grid-auto-rows: 158px;
  flex: 1 0;
  ${({ theme }) => theme.breakpoints.phone`
    display: grid;
    grid-template-columns: repeat(auto-fill, 200px);
  `}
  ${({ theme }) => theme.breakpoints.phablet`
    justify-content: flex-start;
  `}
  ${({ theme }) => theme.breakpoints.desktop`
    grid-auto-rows: 172px;
    grid-template-columns: repeat(auto-fill, 227px);
  `}
`;

interface IProjectThumbnailsGrid {
  children: any;
  className?: string;
}

export const ProjectThumbnailsGrid = ({
  className,
  children,
}: IProjectThumbnailsGrid) => (
  <StyledProjectThumbnailsGrid className={className}>
    {children}
  </StyledProjectThumbnailsGrid>
);
